import mergeImg from 'merge-images';

const img = require.context(`assets`, true);

const montaObj = (obj, x, y, bgIMG, user) => {
    const { game, isGameMD } = user;

    let subtype = 'pure';
    if (obj.main !== obj.sub) subtype = 'hybrid';

    const cardIMG = img(`./${game}/cards/${subtype}/${obj.cardnumber}.${isGameMD ? 'jpg' : 'png'}`).default;

    const retorno = { src: obj ? cardIMG : bgIMG, x, y };
    return retorno;
};

const montaArray = (heroes, user) => {
    const { isGameMD } = user;

    const bgIMG = img(`./bg.png`).default;
    const array = [{ src: bgIMG, x: 0, y: 0, opacity: 0.1 }];

    const x = isGameMD ? [1, 260, 520] : [1, 180, 360];
    const y = isGameMD ? 190 : 120;

    heroes.forEach((hero, i) => {
        array.push(montaObj(hero, x[i], y, bgIMG, user));
    });

    return array;
};

const buildIMG = async (heroes, user) => {
    const arrayImg = montaArray(heroes, user);

    const b64 = await mergeImg(arrayImg);

    return b64.replace(/^data:image\/\w+;base64,/, '');
};

export default buildIMG;
