export const AWS_API = {
    poolId: process.env.REACT_APP_AWS_POOL_ID,
    appClientId: process.env.REACT_APP_AWS_CLIENT_ID
};

export const DK_API = {
    stage: process.env.REACT_APP_DK_STAGE,
    access: process.env.REACT_APP_DK_ACCESS,
    url: process.env.REACT_APP_DK_URL,
    key: process.env.REACT_APP_DK_KEY
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/deckbuilder/home';

const config = {
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 16,
    outlinedFilled: true,
    navType: 'dark', // light, dark
    presetColor: 'theme3', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
