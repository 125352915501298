// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';

// assets
import useConfig from 'hooks/useConfig';
import { IconBulbOff, IconBulb } from '@tabler/icons-react';

// ==============================|| LOCALIZATION ||============================== //

const LightModeSection = () => {
    const { onChangeMenuType } = useConfig();

    const theme = useTheme();

    const toggleLight = () => {
        onChangeMenuType(theme.palette.mode === 'dark' ? 'light' : 'dark');
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        transition: 'all .2s ease-in-out',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            borderColor: theme.palette.primary.main,
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.light
                        }
                    }}
                    aria-haspopup="true"
                    onClick={toggleLight}
                    color="inherit"
                >
                    {theme.palette.mode === 'dark' ? <IconBulbOff /> : <IconBulb />}
                </Avatar>
            </Box>
        </>
    );
};

export default LightModeSection;
