import services from 'utils/mockAdapter';

const rules = {
    ptbr: {
        battle: {
            title: 'Batalha',
            p: [
                'As batalhas são divididas em turnos para cada jogador. Cada turno tem quatro tipos diferentes de fases. E para cada fase, cada monstro pode realizar uma ação.',
                'Primeiro, monte um time de três monstros com 50 cartas de habilidade no total da maneira que preferir. É importante notar que se você escolher muitas cartas de um monstro específico, quando esse monstro morrer em uma partida, todas elas se tornarão inúteis durante a mesma. Portanto, você deve considerar a possibilidade de equilibrar a equipe ou usar um monstro de suporte para manter outro monstro vivo.',
                'Existem duas maneiras de ganhar um jogo que pode ser derrotar os três monstros do oponente (Vitória por Nocaute) ou fazer com que o oponente use todas as cartas do seu baralho (Vitória por Tempo), fazendo com que ele fique sem as ações restantes.'
            ]
        },
        rounding: {
            title: 'Regra do Arredondamento:',
            text: 'Algumas cartas tem o dano ou os pontos de vida afetados pela metade e esse arredondamento deve SEMPRE ser feito em favor da defesa:',
            p: [
                {
                    title: 'Arredondamento para baixo:',
                    items: ['Para todas as cartas ofensivas que cortem o dano pela metade, por exemplo, deve-se arredondar para baixo.']
                },
                {
                    title: 'Arredondamento para cima:',
                    items: [
                        'Para todas as cartas defensivas que refletem metade do dano ou reduzem os pontos de vida pela metade, por exemplo, deve-se arredondar para cima'
                    ]
                }
            ]
        },
        steps: [
            {
                title: 'Fase de Compra',
                text: [
                    'Toda vez que a Fase de Ação do oponente se encerrar, esta será a sua primeira fase. O objetivo desta fase é sempre lhe deixar com 5 cartas na mão antes de iniciar a Fase de Ataque.',
                    'Caso você possua 2 cartas na mão, compre mais 3 e assim por diante, baseado em quantas cartas você possuir em sua mão. Importante ressaltar, caso você já possua 5 cartas na mão, a primeira carta do deck automaticamente virará GUTS. Após ter 5 cartas na mão, a Fase de Ataque será iniciada automaticamente.'
                ]
            },
            {
                title: 'Fase de Batalha',
                text: [
                    'Nesta fase, cada jogador só poderá usar cartas do tipo vermelho, que podem ser POW, INT, SPE ou ENV. E as cartas só podem ser usadas se você possuir a quantidade de GUTS acumulada para ser consumida para pagar o custo da carta escolhida.',
                    'Cada monstro pode executar apenas uma Ação de Monstro durante a Fase de Batalha, a menos que tenha uma carta SPE ou Breeder que altere isso. Também existe uma ação extra de Treinador exclusiva para o uso de uma carta Breeder. Ao executar todas as ações disponíveis, ou se você não tiver GUTS suficientes para fazer qualque ação, você deve prosseguir para a próxima fase: a Fase de Ação.'
                ]
            },
            {
                title: 'Fase de Ação',
                text: [
                    'Esta é a fase onde você pode acumular os GUTS necessários para realizar qualquer ação no jogo, seja de ataque ou defesa. Você precisará escolher quantas cartas quiser de sua mão, até cinco no máximo, e descartá-las do jogo para poder transformá-las em GUTS. É uma espécie de sacrifício, onde você joga fora certas ações para poder executar outras.',
                    'É por isso que seu time deve ter uma variedade de movimentos equilibrados para que você possa ter uma diversidade maior de estratégias no mesmo jogo. É muito importante considerar que se você jogar fora muitas cartas ao mesmo tempo para tentar usar seus ataques mais poderosos, o oponente pode usar muitas cartas de defesa também, e você pode acabar perdendo por falta de cartas. Após encerrar a sua Fase de Ação, será a vez de seu oponente automaticamente.'
                ]
            },
            {
                title: 'Fase de Defesa',
                text: [
                    'Por último, mas não menos importante, a Fase de Defesa ocorre toda vez que seu oponente utilizar qualquer tipo de carta do tipo vermelho (POW, INT, SPE ou ENV). O jogo entrará automaticamente em seu turno defesa, onde você pode interferir com a ação tomada se tiver quaisquer cartas correspondentes, que são as cartas com o cristal azul (DGE ou BLK).',
                    'Em geral, as cartas BLK podem funcionar melhor contra ataques mais fracos, por apenas removerem parte do dano. Já as cartas DGE, por outro lado, podem funcionar melhor contra ataques mais fortes, sofrendo dano mais facilmente contra os ataques fracos. Existem também algumas cartas de defesa que cancelam as ações ENV ou SPE.'
                ]
            }
        ],
        cards: {
            title: 'Tipos de Cartas',
            text: 'Todas as cartas pertecem a algum Monstro, que indica a qual tipo de monstro que pode usar aquela carta, e seu Custo, que indica quantos GUTS são necessários para utilizar aquela carta. A carta pode ser do tipo Any Monster ou Breeder (Qualquer Monstro ou Treinador) que tem atribuições especiais. Any Monster podem ser usadas por qualquer monstro, consumindo a Ação de Monstro de quem usar a carta. Cartas Breeder podem ser utilizadas gastando a Ação de Treinador. Cada carta também possui uma descrição com efeito único.',
            p: [
                {
                    title: 'POW (Poder)',
                    text: 'As cartas de Poder possuem o atributo de dano, que indica quantos PV serão infligidos caso o ataque acerte o seu alvo.'
                },
                {
                    title: 'INT (Inteligência)',
                    text: 'As cartas de Inteligência comportam-se exatamente como as cartas de POW. A única diferença é a natureza do ataque que faz ela ser mais difícil de ser evadida ou bloqueada, na maioria das vezes.'
                },
                {
                    title: 'SPE (Especial)',
                    text: 'As cartas de Especial não possuem dano, porém possuem um efeito único para cada classe, delimitando assim a principal diferença entre elas.'
                },
                {
                    title: 'DGE (Esquiva)',
                    text: 'As cartas de Esquiva só podem ser usadas no turno de defesa e servem para evitar algum ataque direto, evitando totalmente o dano.'
                },
                {
                    title: 'BLK (Bloqueio)',
                    text: 'As cartas de Bloqueio comportam-se exatamente como as cartas de DGE. A única diferença é a natureza da defesa que faz ela não ser tão eficiente quanto uma evasão, bloqueando apenas parte do ataque, na maioria das vezes.'
                },
                {
                    title: 'ENV (Ambiente)',
                    text: 'As cartas de Ambiente são cartas de especiais que se mantém durante todo o jogo. Esta carta altera a dinâmica do combate com uma regra única que só será alterada caso algum outro Ambiente o substitua.'
                }
            ]
        },
        glossary: [
            { item: 'GUTS', enus: 'GUTS', ptbr: 'GUTS' },
            { item: 'HP', enus: 'Health Points', ptbr: 'Pontos de Vida (PV)' },
            { item: 'Any Monster', enus: 'Breeder', ptbr: 'Qualquer Monstro' },
            { item: 'Breeder', enus: 'Breeder', ptbr: 'Treinador' },
            { item: 'POW', enus: 'Power', ptbr: 'Poder' },
            { item: 'INT', enus: 'Intelligence', ptbr: 'Inteligência' },
            { item: 'SPE', enus: 'Special', ptbr: 'Especial' },
            { item: 'DGE', enus: 'Dodge', ptbr: 'Esquiva' },
            { item: 'BLK', enus: 'Block', ptbr: 'Bloqueio' },
            { item: 'ENV', enus: 'Environment', ptbr: 'Ambiente' },
            { item: 'A', enus: 'Aerial', ptbr: 'Aéreo' },
            { item: 'G', enus: 'Ground', ptbr: 'Terrestre' },
            { item: 'W', enus: 'Water', ptbr: 'Aquático' }
        ]
    },
    enus: {
        battle: {
            title: 'Battle',
            p: [
                'Battles are divided into turns for each player. Each turn has four different types of steps. And for each step, every monster can perform an action.',
                "First, assemble a team of three monsters with 50 skill cards in total any way you like. It's important to note that if you choose too many cards from a specific monster, when that monster is KO'd in a game, all of them will become useless during that game. Therefore, you should consider balancing the team or using a support monster to keep another monster alive.",
                "There are two ways to win a game which can be either defeating the opponent's three monsters (Knockout Win) or making the opponent use all the cards in your deck (Time Win), making him run out of remaining actions."
            ]
        },
        rounding: {
            title: 'Rounding Rule:',
            text: 'Some cards have their damage or hit points affected by half and this rounding should ALWAYS be done in favor of defense:',
            p: [
                {
                    title: 'Rounding down:',
                    items: ['For all offensive cards that cut damage in half, for example, you must round down.']
                },
                {
                    title: 'Rounding up:',
                    items: [
                        'For all defensive cards that reflect half damage or reduce hit points by half, for example, you must round up.'
                    ]
                }
            ]
        },
        steps: [
            {
                title: 'Draw Step',
                text: [
                    "Every time your opponent's Action Step ends, this will be your first step. The objective of this step is always to leave you with 5 cards in your hand before starting the Attack Step.",
                    'If you have 2 cards in hand, draw 3 more and so on, based on how many cards you have in your hand. Importantly, if you already have 5 cards in your hand, the first card in the deck will automatically turn GUTS. Once you have 5 cards in hand, the Attack Step will start automatically. '
                ]
            },
            {
                title: 'Attack Step',
                text: [
                    'In this turn, each player can only use cards marked with the red type, which can be POW, INT, SPE or ENV. And cards can only be used if you have the amount of GUTS accumulated to be able to consume while using a certain action.',
                    "Each monster can only perform one Monster Action per Attack Step, unless they have an SPE or Breeder card that changes this. There is also an extra Breeder Action unique to using a Breeder card. When performing all actions for the turn, or if you don't have enough GUTS to do something, you must proceed to the next turn, the Action Step. "
                ]
            },
            {
                title: 'Action Step',
                text: [
                    "This is the turn where you can accumulate the GUTS needed to perform any action in the game, be it offense or defense. You will need to choose as many cards as you want, up to five, and discard them from the game to be able to turn them into GUTS. It's a kind of sacrifice, where you throw away certain actions to perform others.",
                    "That's why your team must have a variety of balanced moves so that you can have a greater diversity of strategies in the same game. It's very important to consider that if you throw away a lot of cards at the same time to try to use your most powerful attacks, your opponent may use a lot of defense cards too, and you may end up losing for lack of cards. After your Action Step ends, it will be your opponent's turn. "
                ]
            },
            {
                title: 'Defense Step',
                text: [
                    'Last but not least, the defense turn occurs every time your opponent uses any type of red card (POW, INT, SPE or ENV). The game will automatically go into your Defense Step, where you can interfere with the action taken if you have any matching cards, which are the cards marked in blue (DGE or BLK).',
                    'In general, BLK cards can work better against weaker attacks, since they often only remove part of the damage. DGE cards, on the other hand, can work better against stronger attacks, taking damage more easily against weaker attacks. There are also some defense cards that cancel ENV or SPE actions.'
                ]
            }
        ],
        cards: {
            title: 'Types of Cards',
            text: 'All cards belong to a specific Monster Type, which indicate which Monster Type can use that card, and the Cost, which indicate how much GUTS are needed to use that card. The Monster Type of the card can be Breeder or Any Monster which has special assignments. Any Monster can be used by any Monster Type, consuming the Monster Action of whoever used the card. Breeder cards can be used by spending the Breeder Action. Each card also has a unique effect description.',
            p: [
                {
                    title: 'POW (Power)',
                    text: 'Power cards have the damage attribute, which indicates how much HP will be inflicted if the attack hits its target.'
                },
                {
                    title: 'INT (Intelligence)',
                    text: 'Intelligence cards behave exactly like POW cards. The only difference is the nature of the attack which makes it harder to defend or dodge most of the time.'
                },
                {
                    title: 'SPE (Special)',
                    text: 'Special cards do not have damage, but have a unique effect for each monster type, thus delimiting the main difference between them.'
                },
                {
                    title: 'DGE (Dodge)',
                    text: 'Dodge cards can only be used in the defense turn and are used to avoid any direct attack, totally avoiding the damage.'
                },
                {
                    title: 'BLK (Block)',
                    text: 'Block cards behave exactly like DGE cards. The only difference is the nature of the defense that makes it not as efficient as an evasion, blocking only part of the attack, most of the time.'
                },
                {
                    title: 'ENV (Environment)',
                    text: 'Environment cards are like Special moves that remain throughout the game. This type of cards changes the dynamics of combat with a unique rule that will only change if some other Environment Card replaces it.'
                }
            ]
        },
        glossary: [
            { item: 'GUTS', enus: 'GUTS', ptbr: 'GUTS' },
            { item: 'HP', enus: 'Health Points', ptbr: 'Pontos de Vida (PV)' },
            { item: 'Any Monster', enus: 'Breeder', ptbr: 'Qualquer Monstro' },
            { item: 'Breeder', enus: 'Breeder', ptbr: 'Treinador' },
            { item: 'POW', enus: 'Power', ptbr: 'Poder' },
            { item: 'INT', enus: 'Intelligence', ptbr: 'Inteligência' },
            { item: 'SPE', enus: 'Special', ptbr: 'Especial' },
            { item: 'DGE', enus: 'Dodge', ptbr: 'Esquiva' },
            { item: 'BLK', enus: 'Block', ptbr: 'Bloqueio' },
            { item: 'ENV', enus: 'Environment', ptbr: 'Ambiente' },
            { item: 'A', enus: 'Aerial', ptbr: 'Aéreo' },
            { item: 'G', enus: 'Ground', ptbr: 'Terrestre' },
            { item: 'W', enus: 'Water', ptbr: 'Aquático' }
        ]
    }
};
// ===========================|| MOCK SERVICES ||=========================== //

services.onGet('/api/rules/mrbc/list').reply(200, { rules });
