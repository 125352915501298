import { openSnackbar } from 'store/slices/snackbar';

export const error = (dispatch, message) =>
    dispatch(
        openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message,
            transition: 'Grow',
            variant: 'alert',
            alert: {
                color: 'error'
            }
        })
    );

export const warning = (dispatch, message) =>
    dispatch(
        openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message,
            transition: 'Grow',
            variant: 'alert',
            alert: {
                color: 'warning'
            }
        })
    );

export const info = (dispatch, message) =>
    dispatch(
        openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message,
            transition: 'Grow',
            variant: 'alert'
        })
    );

export const success = (dispatch, message) =>
    dispatch(
        openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message,
            transition: 'Grow',
            variant: 'alert',
            alert: {
                color: 'success'
            }
        })
    );
