import dashboard from './dashboard';
import editor from './editor';
import system from './system';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
    items: [dashboard, editor, system]
};

export default menuItems;
