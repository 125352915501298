import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const BCCenter = Loadable(lazy(() => import('views/pages/bc-center')));
const Laboratory = Loadable(lazy(() => import('views/pages/laboratory')));

// ==============================|| AUTH ROUTING ||============================== //

const LandingRoute = {
    path: '/',
    element: (
        <NavMotion>
            <MinimalLayout />
        </NavMotion>
    ),
    children: [
        {
            path: '/bc-center',
            element: <BCCenter />
        },
        {
            path: '/laboratory',
            element: <Laboratory />
        }
    ]
};

export default LandingRoute;
