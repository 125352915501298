// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconList, IconInfoCircle, IconListSearch } from '@tabler/icons-react';

// ===========================|| APPLICATION MENU ITEMS ||=========================== //

const system = {
    id: 'system',
    title: <FormattedMessage id="system" />,
    type: 'group',
    children: [
        {
            id: 'effects',
            title: <FormattedMessage id="list-of-effects" />,
            type: 'item',
            icon: IconListSearch,
            url: '/deckbuilder/effects'
        },
        {
            id: 'rules',
            title: <FormattedMessage id="rules" />,
            type: 'item',
            icon: IconList,
            url: '/deckbuilder/rules'
        },
        {
            id: 'about',
            title: <FormattedMessage id="about" />,
            type: 'item',
            icon: IconInfoCircle,
            url: '/deckbuilder/about'
        }
    ]
};

export default system;
