import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import buildIMG from './my-decks/image';
import buildJSON from './my-decks/json';

const jsonFileZip = (deck, gameString) => {
    const json = buildJSON(deck, gameString);
    return new Blob([JSON.stringify(json, null, 2)], { type: 'data:text/json;charset=utf-8' });
};

const jsonFile = (deck, gameString) => {
    const json = buildJSON(deck, gameString);
    return json;
};

const pngFileZip = async (deck, user) => {
    const png = await buildIMG(deck.heroes, user);
    return new Blob([Buffer.from(png, 'base64')], { type: 'application/octet-stream' });
};

const pngFile = async (deck, user) => {
    const png = await buildIMG(deck.heroes, user);
    return png;
};

export const singleDeckZip = async (deck, user) => {
    const { gameString } = user;
    const zip = new JSZip();

    const png = await pngFileZip(deck, user);
    const json = jsonFileZip(deck, gameString);

    zip.file(`${deck.name}.json`, json);
    zip.file(`${deck.name}.png`, png);

    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${deck.user} - ${deck.name}.zip`);
    });
};

export const singleDeckFile = async (deck, user) => {
    const { game, gameString } = user;

    const png = await pngFile(deck, user);
    const json = jsonFile(deck, gameString);

    let tag = deck.tag === '' ? 'No Tag' : deck.tag;
    tag = deck.tag ?? 'No Tag';

    return { folder: tag, name: deck.name, json, png, game };
};

export const deckListZip = (decks, user) => {
    const zip = new JSZip();

    decks.forEach((deck) => {
        let folder;
        if (deck.tag) folder = zip.folder(deck.tag);
        else folder = zip.folder('No Tag');

        const json = jsonFileZip(deck, user.gameString);
        const png = pngFileZip(deck, user);

        folder.file(`${deck.name}.json`, json);
        folder.file(`${deck.name}.png`, png);
    });

    zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'DeckBuilder.zip');
    });
};

export const deckListFile = (decks, user) => {
    const { game, gameString } = user;
    const files = [];

    decks.forEach((deck) => {
        let folder;
        if (deck.tag) folder = deck.tag;
        else folder = 'No Tag';

        const json = jsonFile(deck, gameString);
        const png = pngFile(deck, user);

        files.push({ folder, name: deck.name, json, png, game });
    });
    return files;
};
