import { del, get, post, put } from './http';

export const listMy = (user, signal) =>
    new Promise((resolve, reject) =>
        post(`deck/${user.gameString}/list`, { user: user.name.toLowerCase(), recipe: null }, signal, user.token)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err.message))
    );

export const listPublic = (user, signal) =>
    new Promise((resolve, reject) =>
        get(`deck/${user.gameString}/public`, signal)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err.message))
    );

export const last = (user, signal) =>
    new Promise((resolve, reject) =>
        post(`deck/${user.gameString}/last`, { public: true, recipe: null, user: { $ne: user.name.toLowerCase() } }, signal, user.token)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err.message))
    );

export const listRecipe = (user, signal) =>
    new Promise((resolve, reject) =>
        get(`deck/${user.gameString}/recipe`, signal)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err.message))
    );

export const listTags = (user, signal) =>
    new Promise((resolve, reject) =>
        post(`deck/${user.gameString}/tag`, { user: user.name.toLowerCase() }, signal, user.token)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err.message))
    );

export const listNames = (user, signal) =>
    new Promise((resolve, reject) =>
        post(`deck/${user.gameString}/name`, { user: user.name.toLowerCase() }, signal, user.token)
            .then((response) => resolve(response.data))
            .catch((err) => reject(err.message))
    );

export const exists = (deckName, user, signal) =>
    new Promise((resolve, reject) =>
        post(`deck/${user.gameString}/find`, { name: deckName, user: user.name, recipe: null }, signal, user.token)
            .then((res) => {
                let result;
                if (res.status === 500) {
                    result = { error: true, message: 'Error 500' };
                } else if (res.status === 400) {
                    result = { error: true, message: 'Error 400' };
                } else if (res.status === 404) {
                    result = { error: true, message: 'Error 404' };
                } else if (res.status === 200) {
                    result = { error: false, exists: true };
                } else if (res.status === 204) {
                    result = { error: false, exists: false };
                }
                resolve(result);
            })
            .catch((err) => reject(err.message))
    );

export const save = (deck, user) =>
    new Promise((resolve, reject) =>
        put(`deck/${user.gameString}/save`, deck, user.token)
            .then((res) => {
                let result;
                if (res.status === 500) {
                    result = { error: true, message: 'Error 500' };
                } else if (res.status === 400) {
                    result = { error: true, message: 'Error 400' };
                } else if (res.status === 404) {
                    result = { error: true, message: 'Error 404' };
                } else if (res.status === 200) {
                    result = { error: false, created: false };
                } else if (res.status === 201) {
                    result = { error: false, created: true };
                }
                resolve(result);
            })
            .catch((err) => reject(err.message))
    );

export const togglePublic = (deck, user) => {
    const newDeck = {
        name: deck.name,
        cards: deck.cards,
        game: deck.game,
        user: user.name,
        heroes: deck.heroes,
        public: !deck.public,
        tag: deck.tag
    };

    return save(newDeck, user);
};

export const rename = (deck, newName, user) => {
    const newDeck = {
        name: newName,
        nomeAntigo: deck.name,
        cards: deck.cards,
        game: deck.game,
        user: user.name,
        heroes: deck.heroes,
        public: deck.public,
        tag: deck.tag
    };

    return save(newDeck, user);
};

export const importDeck = (deck, user) => {
    const newDeck = {
        name: `${deck.user}'s ${deck.name}`,
        cards: deck.cards,
        game: deck.game,
        user: user.name,
        extra: deck.extra,
        heroes: deck.heroes,
        public: false,
        tag: 'Imported Decks'
    };

    return save(newDeck, user);
};

export const remove = (deckName, user) =>
    new Promise((resolve, reject) =>
        del(`deck/${user.gameString}/delete`, { name: deckName, user: user.name, recipe: null }, user.token)
            .then((res) => {
                let result;
                if (res.status === 500) {
                    result = { error: true, message: 'Error 500' };
                } else if (res.status === 400) {
                    result = { error: true, message: 'Error 400' };
                } else if (res.status === 404) {
                    result = { error: true, message: 'Error 404' };
                } else if (res.status === 200) {
                    result = { error: false };
                }
                resolve(result);
            })
            .catch((err) => reject(err.message))
    );
