import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// editor routing
const CreateNewDeck = Loadable(lazy(() => import('views/editor/create/new-deck')));
const CreateFromRecipe = Loadable(lazy(() => import('views/editor/create/recipe')));
const EditorMyDecks = Loadable(lazy(() => import('views/editor/edit/my-decks')));
const EditorImport = Loadable(lazy(() => import('views/editor/edit/public')));

// system routing
const SystemAbout = Loadable(lazy(() => import('views/system/about')));
const SystemEffects = Loadable(lazy(() => import('views/system/effects')));
const SystemRules = Loadable(lazy(() => import('views/system/rules')));

// dashboard routing
const DashboardHome = Loadable(lazy(() => import('views/dashboard/home')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/analytics')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/deckbuilder/create',
            element: <CreateNewDeck />
        },
        {
            path: '/deckbuilder/recipe',
            element: <CreateFromRecipe />
        },
        {
            path: '/deckbuilder/my-decks',
            element: <EditorMyDecks />
        },
        {
            path: '/deckbuilder/public-decks',
            element: <EditorImport />
        },
        {
            path: '/deckbuilder/about',
            element: <SystemAbout />
        },
        {
            path: '/deckbuilder/effects',
            element: <SystemEffects />
        },
        {
            path: '/deckbuilder/rules',
            element: <SystemRules />
        },
        {
            path: '/deckbuilder/home',
            element: <DashboardHome />
        },
        {
            path: '/deckbuilder/analytics',
            element: <DashboardAnalytics />
        }
    ]
};

export default MainRoutes;
