import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';

// third-party
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails, CognitoRefreshToken } from 'amazon-cognito-identity-js';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { AWS_API } from 'config';
import { error, warning } from 'utils/alert';
import { useDispatch } from 'react-redux';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

const setSession = (session) => {
    if (session) {
        localStorage.setItem('refreshToken', session.getRefreshToken().getToken());
    } else {
        localStorage.removeItem('refreshToken');
    }
};

const setUser = (session) => {
    const game = session.getIdToken().payload['custom:game'];
    let gameString = 'mrbc';
    let isGameMD = false;

    if (game === 'M&D') {
        gameString = 'md';
        isGameMD = true;
    }

    return {
        name: session.getIdToken().payload['cognito:username'],
        email: session.getIdToken().payload.email,
        game,
        gameString,
        isGameMD,
        token: session.getIdToken().getJwtToken()
    };
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext(null);

export const AWSCognitoProvider = ({ children }) => {
    const dispatcher = useDispatch();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const intl = useIntl();

    useEffect(() => {
        const init = async () => {
            try {
                const refreshString = window.localStorage.getItem('refreshToken');
                const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshString });

                if (refreshString) {
                    const cognitoUserObj = userPool.getCurrentUser();
                    cognitoUserObj.refreshSession(refreshToken, (err, newSession) => {
                        if (err) console.error(err);
                        setSession(newSession);
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                user: setUser(newSession)
                            }
                        });
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                error(dispatcher, err.message);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, [dispatcher]);

    const login = async (user, password) => {
        const usr = new CognitoUser({
            Username: user,
            Pool: userPool
        });

        const authData = new AuthenticationDetails({
            Username: user,
            Password: password
        });

        usr.authenticateUser(authData, {
            onSuccess: (session) => {
                setSession(session);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: setUser(session)
                    }
                });
            },
            onFailure: (_err) => {
                if (_err.code === 'UserNotConfirmedException') {
                    usr.resendConfirmationCode((err) => {
                        if (err) error(dispatcher, err.message);
                        else warning(dispatcher, intl.formatMessage({ id: 'check-your-email' }));
                    });
                } else error(dispatcher, _err.message);
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                console.log(userAttributes);
                console.log(requiredAttributes);
                // // User was signed up by an admin and must provide new
                // // password and required attributes, if any, to complete
                // // authentication.
                // // the api doesn't accept this field back
                // delete userAttributes.email_verified;
                // // unsure about this field, but I don't send this back
                // delete userAttributes.phone_number_verified;
                // // Get these details and call
                // usr.completeNewPasswordChallenge(password, userAttributes, requiredAttributes);
            }
        });
    };

    const forgotPassword = (email) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise((resolve, reject) =>
            usr.forgotPassword({
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                }
            })
        );
    };

    const resetPassword = (email, password, code) => {
        const usr = new CognitoUser({
            Username: email,
            Pool: userPool
        });
        return new Promise((resolve, reject) =>
            usr.confirmPassword(code, password, {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                }
            })
        );
    };

    const register = (user, email, password) =>
        new Promise((resolve, rej) => {
            userPool.signUp(
                user,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'custom:game', Value: 'MRBC' })
                ],
                [],
                async (err, result) => {
                    if (err) {
                        rej(err);
                        return;
                    }
                    resolve(result);
                }
            );
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            loggedInUser.signOut();
            dispatch({ type: LOGOUT });
        }
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider value={{ ...state, login, logout, register, resetPassword, forgotPassword }}>
            {children}
        </AWSCognitoContext.Provider>
    );
};

AWSCognitoProvider.propTypes = {
    children: PropTypes.node
};

export default AWSCognitoContext;
