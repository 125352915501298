import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import isElectron from 'is-electron';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';

// project imports
import Transitions from 'ui-component/extended/Transitions';

// assets
import useConfig from 'hooks/useConfig';
import { IconRefresh, IconSettings, IconEraser, IconDownload } from '@tabler/icons-react';
import { success, error, warning } from 'utils/alert';
import { listMy } from 'rest/deck';
import useAuth from 'hooks/useAuth';
import { deckListFile } from 'views/editor/edit/downloader';
import { Link } from 'react-router-dom';

export const ImgStyle = styled('img')(() => ({
    height: '150%',
    width: '150%',
    paddingTop: '20%',
    marginLeft: '-25%',
    opacity: 0.5
}));

// ==============================|| SETTINGS ||============================== //

const SettingsSection = () => {
    const { borderRadius } = useConfig();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { user } = useAuth();

    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleClearCache = () => {
        if (!isElectron()) return;

        window.api
            .invoke('clear-cache')
            .then((filesDeleted) => {
                if (filesDeleted > 0) {
                    success(
                        dispatch,
                        `${intl.formatMessage({ id: 'cache-clear-success' })} (${filesDeleted} ${intl.formatMessage({
                            id: 'files-deleted'
                        })})`
                    );
                } else {
                    warning(dispatch, intl.formatMessage({ id: 'cache-clear-warning' }));
                }
            })
            .catch((err) => {
                console.error(err);
                error(dispatch, intl.formatMessage({ id: 'cache-clear-error' }));
            });
        setOpen(false);
    };

    const handleSyncDecks = () => {
        if (!isElectron()) return;

        warning(dispatch, intl.formatMessage({ id: 'sync-decks-start' }));
        listMy(user).then((decks) => {
            window.api
                .invoke('sync-decks', user.game)
                .then(() => {
                    const files = deckListFile(decks, user);

                    files.forEach((file) => {
                        file.png.then((obj) => {
                            file.png = obj;
                            window.api.invoke('write-file', JSON.stringify(file)).then(() => {
                                success(
                                    dispatch,
                                    `${intl.formatMessage({ id: 'sync-decks-success' })} (${decks.length} ${intl.formatMessage({
                                        id: 'decks-downloaded'
                                    })})`
                                );
                            });
                        });
                    });
                })
                .catch((err) => {
                    console.error(err);
                    error(dispatch, intl.formatMessage({ id: 'sync-decks-error' }));
                });
        });
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    [theme.breakpoints.down('md')]: {
                        ml: 1
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        transition: 'all .2s ease-in-out',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            borderColor: theme.palette.primary.main,
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <IconSettings />
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 0 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <List
                                        component="nav"
                                        sx={{
                                            width: '100%',
                                            minWidth: 200,
                                            maxWidth: 280,
                                            bgcolor: theme.palette.background.paper,
                                            borderRadius: `${borderRadius}px`,
                                            [theme.breakpoints.down('md')]: {
                                                maxWidth: 250
                                            }
                                        }}
                                    >
                                        {isElectron() && (
                                            <ListItemButton onClick={(event) => handleClearCache(event)}>
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            <Grid item md={2}>
                                                                <IconEraser stroke={1.5} size="20px" />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography color="textPrimary">Clear Cache</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                        )}
                                        {isElectron() && (
                                            <ListItemButton onClick={(event) => handleSyncDecks(event)}>
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            <Grid item md={2}>
                                                                <IconRefresh stroke={1.5} size="20px" />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Typography color="textPrimary">Sync Decks</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                        )}
                                        {!isElectron() && (
                                            <ListItemButton onClick={(event) => handleClose(event)}>
                                                <ListItemText
                                                    primary={
                                                        <Grid container>
                                                            <Grid item md={2}>
                                                                <IconDownload stroke={1.5} size="20px" />
                                                            </Grid>
                                                            <Grid item md={10}>
                                                                <Link
                                                                    style={{ textDecoration: 'none' }}
                                                                    to="https://mrbc.s3.amazonaws.com/DeckBuilderSetup.exe"
                                                                    download
                                                                >
                                                                    <Typography color="textPrimary">DeckBuilderApp</Typography>
                                                                </Link>
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                />
                                            </ListItemButton>
                                        )}
                                    </List>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default SettingsSection;
