import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

// material-ui
import Chip from 'ui-component/extended/Chip';

import isElectron from 'is-electron';
import { CircularProgress, LinearProgress, Box, Typography } from '@mui/material';
import { success, error, warning } from 'utils/alert';
import { useDispatch } from 'react-redux';

const LinearProgressWithLabel = ({ value }) => (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" value={value} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
        </Box>
    </Box>
);

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired
};

// ==============================|| SIDEBAR - CHECK UPDATE ||============================== //

const CheckUpdate = () => {
    const dispatcher = useDispatch();
    const intl = useIntl();

    const [checking, setChecking] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    // const [progress, setProgress] = useState(false);

    const handleCheckUpdate = () => {
        if (!isElectron()) return;

        if (checking) return;
        if (downloading) return;

        if (downloaded) {
            window.api.send('do-update');
        } else {
            setChecking(true);
            window.api.send('update-check');
        }
    };

    const validateTitle = () => {
        if (!isElectron()) return '';

        let title = intl.formatMessage({ id: 'search-for-update' });
        if (downloading) title = intl.formatMessage({ id: 'downloading' });
        else if (downloaded) title = intl.formatMessage({ id: 'restart-and-update' });
        else if (!isElectron()) title = '';

        return title;
    };

    const validateColor = () => {
        let color = 'secondary';
        if (!isElectron()) return color;

        if (downloading) color = 'error';
        else if (downloaded) color = 'success';

        return color;
    };

    useEffect(() => {
        if (!isElectron()) return;

        window.api.receive('checking-for-update', () => {
            setChecking(true);
            setDownloading(false);
        });

        window.api.receive('update-not-available', () => {
            success(dispatcher, intl.formatMessage({ id: 'update-not-available' }));
            setChecking(false);
            setDownloading(false);
        });

        window.api.receive('update-error', (e, err) => {
            error(dispatcher, intl.formatMessage({ id: 'update-error' }));
            console.error(err);
        });

        window.api.receive('update-available', () => {
            warning(dispatcher, intl.formatMessage({ id: 'update-available' }));
            setChecking(false);
            setDownloading(true);
        });

        window.api.receive('update-downloaded', () => {
            success(dispatcher, intl.formatMessage({ id: 'update-downloaded' }));
            setDownloaded(true);
            setChecking(false);
            setDownloading(false);
        });

        // window.api.receive('download-progress', (e, obj) => {
        //     if (obj) {
        //         const percentual = Number(obj.percent.toFixed(2));
        //         setProgress(percentual);
        //         setChecking(false);
        //     }
        // });
    }, [dispatcher, intl]);

    return (
        <>
            <Chip
                title={validateTitle()}
                onClick={(event) => handleCheckUpdate(event)}
                label={process.env.REACT_APP_VERSION}
                chipcolor={validateColor()}
                size="small"
                disabled={checking || !isElectron()}
                sx={{ cursor: 'pointer' }}
            />
            {/* {downloading && (
                <Box sx={{ width: '100%', marginTop: '4px' }}>
                    <LinearProgressWithLabel value={progress} />
                </Box>
            )} */}
            {checking || downloading ? <CircularProgress sx={{ marginTop: '10px' }} /> : <></>}
        </>
    );
};

export default CheckUpdate;
