// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardList, IconLock, IconPencil, IconWorld } from '@tabler/icons-react';

// ===========================|| APPLICATION MENU ITEMS ||=========================== //

const editor = {
    id: 'editor',
    title: <FormattedMessage id="editor" />,
    type: 'group',
    children: [
        {
            id: 'create',
            title: <FormattedMessage id="create" />,
            type: 'item',
            icon: IconPencil,
            url: '/deckbuilder/create',
            breadcrumbs: false
        },
        {
            id: 'create-recipe',
            title: <FormattedMessage id="recipe" />,
            type: 'item',
            icon: IconClipboardList,
            url: '/deckbuilder/recipe',
            breadcrumbs: false
        },
        {
            id: 'edit',
            title: <FormattedMessage id="my-decks" />,
            type: 'item',
            icon: IconLock,
            url: '/deckbuilder/my-decks',
            breadcrumbs: false
        },
        {
            id: 'edit-public',
            title: <FormattedMessage id="public-decks" />,
            type: 'item',
            icon: IconWorld,
            url: '/deckbuilder/public-decks',
            breadcrumbs: false
        }
    ]
};

export default editor;
