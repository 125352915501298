// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import logo from 'assets/landing/logo-white.png';
import logoDark from 'assets/landing/logo-dark.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ size }) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <img src={theme.palette.mode === 'dark' ? logo : logoDark} alt="DeckBuilder" width={size} />
    );
};

export default Logo;

Logo.propTypes = {
    size: PropTypes.string
};

Logo.defaultProps = {
    size: '180'
};
