import services from 'utils/mockAdapter';

const rules = {
    ptbr: {
        battle: {
            title: 'Batalha',
            p: [
                'As batalhas são divididas em turnos para cada jogador. Cada turno tem quatro tipos diferentes de fases. E para cada fase, cada herói pode realizar uma ação.',
                'Primeiro, monte um time de três heróis com 60 cartas de habilidade no total da maneira que preferir. É importante notar que se você escolher muitas cartas de um herói específico, quando esse herói morrer em uma partida, todas elas se tornarão inúteis durante a mesma. Portanto, você deve considerar a possibilidade de equilibrar a equipe ou usar um herói de suporte para manter outro herói vivo.',
                'Existem duas maneiras de ganhar um jogo que pode ser derrotar os três heróis do oponente (Vitória por Nocaute) ou fazer com que o oponente use todas as cartas do seu baralho (Vitória por Tempo), fazendo com que ele fique sem ações restantes.'
            ]
        },
        rounding: {
            title: 'Regra do Arredondamento:',
            text: 'Algumas cartas tem o dano ou os pontos de vida afetados pela metade e esse arredondamento deve SEMPRE ser feito em favor da defesa:',
            p: [
                {
                    title: 'Arredondamento para baixo:',
                    items: ['Para todas as cartas ofensivas que cortem o dano pela metade, por exemplo, deve-se arredondar para baixo.']
                },
                {
                    title: 'Arredondamento para cima:',
                    items: [
                        'Para todas as cartas defensivas que refletem metade do dano ou reduzem os pontos de vida pela metade, por exemplo, deve-se arredondar para cima'
                    ]
                }
            ]
        },
        steps: [
            {
                title: 'Fase de Compra',
                text: [
                    'Toda vez que a Fase de Ação do oponente se encerrar, esta será a sua primeira fase. O objetivo desta fase é sempre lhe deixar com 6 cartas na mão antes de iniciar a Fase de Ataque.',
                    'Caso você possua 2 cartas na mão, compre mais 4 e assim por diante, baseado em quantas cartas você possuir em sua mão. Importante ressaltar, caso você já possua 6 cartas na mão, a primeira carta do deck automaticamente virará PA. Após ter 6 cartas na mão, a Fase de Ataque será iniciada automaticamente.'
                ]
            },
            {
                title: 'Fase de Batalha',
                text: [
                    'Nesta fase, cada jogador só poderá usar cartas com o cristal vermelho, que podem ser ATK, TEC, SKL ou DOM. E as cartas só podem ser usadas se você possuir a quantidade de PA acumulada para ser consumida para pagar o custo da carta escolhida.',
                    'Cada herói pode executar apenas uma Ação de Herói durante a Fase de Batalha, a menos que tenha uma carta SKL ou Feitiço que altere isso. Também existe uma ação extra exclusiva para o uso de uma carta Feitiço. Ao executar todas as ações disponíveis, ou se você não tiver PA suficientes para fazer qualque ação, você deve prosseguir para a próxima fase: a Fase de Ação.'
                ]
            },
            {
                title: 'Fase de Ação',
                text: [
                    'Esta é a fase onde você pode acumular os PA necessários para realizar qualquer ação no jogo, seja de ataque ou defesa. Você precisará escolher quantas cartas quiser de sua mão, até seis no máximo, e descartá-las do jogo para poder transformá-las em PA. É uma espécie de sacrifício, onde você joga fora certas ações para poder executar outras.',
                    'É por isso que seu time deve ter uma variedade de movimentos equilibrados para que você possa ter uma diversidade maior de estratégias no mesmo jogo. É muito importante considerar que se você jogar fora muitas cartas ao mesmo tempo para tentar usar seus ataques mais poderosos, o oponente pode usar muitas cartas de defesa também, e você pode acabar perdendo por falta de cartas. Após encerrar a sua Fase de Ação, será a vez de seu oponente automaticamente.'
                ]
            },
            {
                title: 'Fase de Defesa',
                text: [
                    'Por último, mas não menos importante, a Fase de Defesa ocorre toda vez que seu oponente utilizar qualquer tipo de carta com o cristal vermelho (ATK, TEC, SKL ou DOM). O jogo entrará automaticamente em seu turno defesa, onde você pode interferir com a ação tomada se tiver quaisquer cartas correspondentes, que são as cartas com o cristal azul (GRD ou EVD).',
                    'Em geral, as cartas GRD podem funcionar melhor contra ataques mais fracos, por apenas removerem parte do dano. Já as cartas EVD, por outro lado, podem funcionar melhor contra ataques mais fortes, sofrendo dano mais facilmente contra os ataques fracos. Existem também algumas cartas de defesa que cancelam as ações DOM ou SKL.'
                ]
            }
        ],
        cards: {
            title: 'Tipos de Cartas',
            text: 'Todas as cartas pertecem a alguma Classe, que indica a qual tipo de herói que pode usar aquela carta, e seu Custo, que indica quantos PA são necessários para utilizar aquela carta. A Classe da carta também pode ser Feitiço ou Talento que tem atribuições especiais. Talentos podem ser usadas por qualquer classe de heróis, consumindo o turno daquele herói. Feitiços podem ser utilizadas gastando a Ação de Magia. Cada carta também possui uma descrição com efeito único.',
            p: [
                {
                    title: 'ATK (Ataque)',
                    text: 'As cartas de Ataque possuem o atributo de dano, que indica quantos PV serão infligidos caso o ataque acerte o seu alvo.'
                },
                {
                    title: 'TEC (Técnica)',
                    text: 'As cartas de Técnica comportam-se exatamente como as cartas de ATK. A única diferença é a natureza do ataque que faz ela ser mais difícil de ser evadida ou bloqueada, na maioria das vezes.'
                },
                {
                    title: 'SKL (Habilidade)',
                    text: 'As cartas de Habilidade não possuem dano, porém possuem um efeito único para cada classe, delimitando assim a principal diferença entre elas.'
                },
                {
                    title: 'EVD (Evasão)',
                    text: 'As cartas de Evasão só podem ser usadas no turno de defesa e servem para evitar algum ataque direto, evitando totalmente o dano.'
                },
                {
                    title: 'GRD (Guarda)',
                    text: 'As cartas de Guarda comportam-se exatamente como as cartas de EVD. A única diferença é a natureza da defesa que faz ela não ser tão eficiente quanto uma evasão, bloqueando apenas parte do ataque, na maioria das vezes.'
                },
                {
                    title: 'DOM (Domínio)',
                    text: 'As cartas de Domínio geralmente são Feitiços que se mantém durante todo o jogo. Esta carta altera a dinâmica do combate com uma regra única que só será alterada caso algum outro Domínio o substitua.'
                }
            ]
        },
        glossary: [
            { item: 'AP', enus: 'Action Points', ptbr: 'Pontos de Ação (PA)' },
            { item: 'HP', enus: 'Health Points', ptbr: 'Pontos de Vida (PV)' },
            { item: 'Talent', enus: 'Talent', ptbr: 'Talento' },
            { item: 'Spell', enus: 'Spell', ptbr: 'Feitiço' },
            { item: 'ATK', enus: 'Attack', ptbr: 'Ataque' },
            { item: 'TEC', enus: 'Technique', ptbr: 'Técnica' },
            { item: 'SKL', enus: 'Skill', ptbr: 'Habilidade' },
            { item: 'EVD', enus: 'Evade', ptbr: 'Evasão' },
            { item: 'GRD', enus: 'Guard', ptbr: 'Guarda' },
            { item: 'DOM', enus: 'Domain', ptbr: 'Domínio' },
            { item: 'S', enus: 'Spiritual', ptbr: 'Espiritual (E)' },
            { item: 'M', enus: 'Mundane', ptbr: 'Mundano (M)' },
            { item: 'U', enus: 'Undead', ptbr: 'Zumbi (Z)' }
        ]
    },
    enus: {
        battle: {
            title: 'Battle',
            p: [
                'Battles are divided into turns for each player. Each turn has four different types of steps. And for each step, every hero can perform an action.',
                "First, assemble a team of three heroes with 60 skill cards in total any way you like. It's important to note that if you choose too many cards from a specific hero, when that hero dies in a match, all of them will become useless during that match. Therefore, you should consider balancing the team or using a support hero to keep another hero alive.",
                "There are two ways to win a match which can be either defeating the opponent's three heroes (Knockout Win) or making the opponent use all the cards in your deck (Time Win), making him run out of remaining actions."
            ]
        },
        rounding: {
            title: 'Rounding Rule:',
            text: 'Some cards have their damage or hit points affected by half and this rounding should ALWAYS be done in favor of defense:',
            p: [
                {
                    title: 'Rounding down:',
                    items: ['For all offensive cards that cut damage in half, for example, you must round down.']
                },
                {
                    title: 'Rounding up:',
                    items: [
                        'For all defensive cards that reflect half damage or reduce hit points by half, for example, you must round up.'
                    ]
                }
            ]
        },
        steps: [
            {
                title: 'Draw Step',
                text: [
                    "Every time your opponent's Action Step ends, this will be your first step. The objective of this step is to always get 6 cards in your hand before starting the Attack Step.",
                    'If you have 2 cards in hand, draw 4 more and so on, based on how many cards you have in your hand. Importantly, if you already have 6 cards in your hand, the first card in the deck will automatically turn AP. Once you have 6 cards in hand, your Attack Step will start automatically. '
                ]
            },
            {
                title: 'Attack Step',
                text: [
                    'In this step, each player can only use cards marked with the red crystal, which can be ATK, TEC, SKL or DOM. And cards can only be used if you have the amount of AP accumulated to be able to consume while using a certain action.',
                    "Each hero can only perform one Hero Action per Attack Step, unless they have an SKL or Spell card that changes this. There is also an extra Magic Action unique to be using a Spell card. After performing all actions available, or if you don't have enough AP to do something, you must proceed to the next turn: the Action Step. "
                ]
            },
            {
                title: 'Action Step',
                text: [
                    "This is the turn where you can accumulate the AP needed to perform any action in the game, be it offense or defense. You will need to choose as many cards as you want, up to six, and discard them from the game to be able to turn them into AP. It's a kind of sacrifice, where you throw away certain actions to perform others.",
                    "That's why your team must have a variety of balanced moves so that you can have a greater diversity of strategies in the same game. It's very important to consider that if you throw away a lot of cards at the same time to try to use your most powerful attacks, your opponent may use a lot of defense cards too, and you may end up losing for lack of cards. After your Action Step ends, it will be your opponent's turn. "
                ]
            },
            {
                title: 'Defense Step',
                text: [
                    'Last but not least, the Defense Step occurs every time your opponent uses any type of red crystal card (ATK, TEC, SKL or DOM). The game will automatically go into your defense turn, where you can interfere with the action taken if you have any matching cards, which are the cards marked with the blue crystal (GRD or EVD).',
                    'In general, GRD cards can work better against weaker attacks, since they often only remove part of the damage. EVD cards, on the other hand, can work better against stronger attacks, taking damage more easily against weaker attacks. There are also some defense cards that cancel DOM or SKL actions.'
                ]
            }
        ],
        cards: {
            title: 'Types of Cards',
            text: 'All cards belong to a specific Class, which indicate which Class can use that card, and the Cost, which indicate how much AP are needed to use that card. The Class of the card can be Talent or Spell which has special assignments. Talents can be used by any Class, consuming the Hero Action of whoever used the card. Spell can be used by spending your Magic Action. Each card also has a unique effect description.',
            p: [
                {
                    title: 'ATK (Attack)',
                    text: 'Attack cards have the damage attribute, which indicates how much HP will be inflicted if the attack hits its target.'
                },
                {
                    title: 'TEC (Technique)',
                    text: 'Technique cards behave exactly like ATK cards. The only difference is the nature of the attack which makes it harder to defend or dodge most of the time.'
                },
                {
                    title: 'SKL (Skill)',
                    text: 'Skill cards do not have damage, but have a unique effect for each monster type, thus delimiting the main difference between them.'
                },
                {
                    title: 'EVD (Evade)',
                    text: 'Evade cards can only be used in the Defense Step and are used to avoid any direct attack, totally avoiding the damage.'
                },
                {
                    title: 'GRD (Guard)',
                    text: 'Guard cards behave exactly like EVD cards. The only difference is the nature of the defense that makes it not as efficient as an evasion, blocking only part of the attack, most of the time.'
                },
                {
                    title: 'DOM (Domain)',
                    text: 'Domain cards are like Special moves that remain throughout the game. This type of cards changes the dynamics of combat with a unique rule that will only change if some other Domain Card replaces it.'
                }
            ]
        },
        glossary: [
            { item: 'AP', enus: 'Action Points', ptbr: 'Pontos de Ação (PA)' },
            { item: 'HP', enus: 'Health Points', ptbr: 'Pontos de Vida (PV)' },
            { item: 'Talent', enus: 'Talent', ptbr: 'Talento' },
            { item: 'Spell', enus: 'Spell', ptbr: 'Feitiço' },
            { item: 'ATK', enus: 'Attack', ptbr: 'Ataque' },
            { item: 'TEC', enus: 'Technique', ptbr: 'Técnica' },
            { item: 'SKL', enus: 'Skill', ptbr: 'Habilidade' },
            { item: 'EVD', enus: 'Evade', ptbr: 'Evasão' },
            { item: 'GRD', enus: 'Guard', ptbr: 'Guarda' },
            { item: 'DOM', enus: 'Domain', ptbr: 'Domínio' },
            { item: 'S', enus: 'Spiritual', ptbr: 'Espiritual (E)' },
            { item: 'M', enus: 'Mundane', ptbr: 'Mundano (M)' },
            { item: 'U', enus: 'Undead', ptbr: 'Zumbi (Z)' }
        ]
    }
};
// ===========================|| MOCK SERVICES ||=========================== //

services.onGet('/api/rules/md/list').reply(200, { rules });
