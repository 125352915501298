import axios from 'axios';

import { DK_API } from 'config';

const getHeaders = (token) => {
    const headers = { 'x-api-key': DK_API.key };
    if (token) headers.Authorization = `Bearer ${token}`;
    return headers;
};

export const get = (path, signal, token) =>
    new Promise((resolve, reject) =>
        axios
            .get(`${DK_API.url}/${DK_API.stage}/${path}`, { headers: getHeaders(token), signal })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err))
    );

export const post = (path, body, signal, token) =>
    new Promise((resolve, reject) =>
        axios
            .post(`${DK_API.url}/${DK_API.stage}/${path}`, body, { headers: getHeaders(token), signal })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err))
    );

export const put = (path, body, token) =>
    new Promise((resolve, reject) =>
        axios
            .put(`${DK_API.url}/${DK_API.stage}/${path}`, body, { headers: getHeaders(token) })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err))
    );

export const del = (path, body, token) =>
    new Promise((resolve, reject) =>
        axios
            .post(`${DK_API.url}/${DK_API.stage}/${path}`, body, { headers: getHeaders(token) })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err))
    );
